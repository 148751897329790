@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
.error-img{
  width: 100%;
}
.backbtn{
  text-align: center;
    display: flex;
    margin: 5rm;
    position: absolute;
    top: 71%;
    right: 44%;
    justify-content: center;
    padding: 2rem;
}

.list-item li{
  list-style-type: decimal;
}

.table-heading{
    text-align: center;
    font-size: 1.0rem;
    color: black;
    padding: 2rem;
   
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 50%;
    border: 2px solid black;
    margin: auto;
    
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  table, th, td {
    border: 1px solid black;
  }
  
  .table-content{
    background-color: #dddddd;
   padding-bottom: 2rem;
  }

  @media(min-width:280px) and (max-width:768px){
    .table-heading{
      font-size: 0.8rem;
     
  }

  td, th {
    padding: 8px;
  }

  table {
    width: 90%;
    
  }

  }
.benefits-section{
    background-color: #0039CC;
    border-color: #0039CC;
    color: #F3F8FF;
}

.benefit-top-heading{
    text-align: center;
    font-family: "Poppins";
    font-size: 1.4rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;
    text-transform: none;
    padding-top: 4rem;
}



.main-benefits-section{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4rem;
    padding-bottom: 4rem;
    padding-top: 2rem;
}
.top-heading{
    font-family: "Poppins";
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 0.00rem;
    line-height: 1.3;
    text-transform: none;
    text-align: center;
}

.benefit-para{
    font-family: "Raleway";
    font-size: 1.2rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 2rem;
    text-transform: none;
    opacity: 0.8;
    margin-top: 10px;
    text-align: center;


}
.vicon{
    text-align: center;
    box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 1.0);
    background-color: #0C72FF;
    color: #F3F8FF;
    border-color: #0C72FF;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    display: flex;
    align-self: center;
    justify-content: center;
    padding-top: 0.4rem;
}
.video-icon{
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

@media(min-width:280px) and (max-width:768px){

    .main-benefits-section{
        display: flex;
       flex-direction: column;
    }
}
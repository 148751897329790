.slick-slide img {
    display: block;
    border-radius: 51px;
    width: 495px;
    height: 265px;
}

@media(min-width:280px) and (max-width:768px){

    .slick-slide img {
        width: 495px;
        height: 265px;
    }
}

 @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.banner{
    display: flex;
    justify-content: space-evenly;
    background-color: #ebeae4ab;
    height: 48vh;
    padding-top: 2rem;
    font-family: 'Roboto', sans-serif;
    
  }
  .heading{
    color: black;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 2.80rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;
    text-transform: none;
  }
  .para{
    font-family: "Raleway";
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 1.4;
    color: #F3F8FF;
    text-transform: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
  }

  .Bimg{
    width: 580px;
    height: 329px;
  }

  .css-e53awj-MuiStack-root {
    padding-top: 1rem !important;
    
   }

   .css-sghohy-MuiButtonBase-root-MuiButton-root {
     padding: 2rem;
    border-radius: 0.5rem !important;
   }


 @media (min-width:280px) and (max-width: 768px) {

    .banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4rem;
        height: 100%;
        
      }
      .heading{
        font-size: 1.5rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;   
    padding-left: 1rem;  
    margin-top: 3rem; 
      }

      .para{
        font-size: 1.4rem;
        letter-spacing: 0.00rem;
        opacity: 0.8;
        padding-top: 1rem;
        padding-left: 1rem;
      }

      .css-e53awj-MuiStack-root {
       padding: 1rem;
      }

     

      .left-img{
        display: flex !important;
        justify-content: center !important;
        padding-bottom: 2.5rem;
        margin: 1rem;
      }

	.Bimg{
        width: 77%;
        height: 100%;
      }
      .css-m69qwo-MuiStack-root {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }


}
 

.card-section{

.card-top-text{
    font-size: 2.8rem;
    letter-spacing: 0rem;
    text-align: center;
    margin: 1.8rem 0rem 1.8rem 0rem;
    font-weight: bold;
}

.cardimg{
    width: 311px;
    height: auto;
    box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    padding-left: -1px;
    padding-top: -1px;
    padding-right: -1px;
    padding-bottom: 0px;
    margin-top: 2rem;
}
.card-img{
    margin-left: 1rem;
}
.card-heading{
    text-align: center;
    background-color: #03a9f4;
    margin-bottom: 2.5rem;
    color: #28292A;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: 1rem;
    
}
.card-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1rem;
    row-gap: 0.2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #28292A;
    font-size: 1rem;
}
.card-icons{
    margin-right: 0.4rem;
}
.card-dev{
    font-family: "Raleway";
    font-size: 1.0rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 1.55;
    align-items: baseline;
    text-align: left;
    text-transform: none;
    margin-left: 1rem;
    margin-bottom: 1rem;
}


.css-e53awj-MuiStack-root {
    justify-content: center;
    display: flex;
    
}

.cards {
    box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
    padding-top: 20px;
    padding: 1rem;
    

}
.azure{
    background-color: #673ab7;
}
.data{
    background-color: #ff9800;
}
.main-card{
    display: flex;
    justify-content: center;
    column-gap: 1rem;
}
.cards-all-btn{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}
.secon-main-card{
    padding-top: 2rem;
}
}

@media (min-width:280px) and (max-width:768px){

    .card-section{
    .main-card{
        display: flex;
        flex-direction: column;
    }
    .card-top-text{
        font-size: 1.8rem;
    }
}
}
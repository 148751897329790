@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a:hover{
  text-decoration:none;
  outline:none;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
.error-img{
  width: 100%;
}
.backbtn{
  text-align: center;
    display: flex;
    margin: 5rm;
    position: absolute;
    top: 71%;
    right: 44%;
    justify-content: center;
    padding: 2rem;
}

.list-item li{
  list-style-type: decimal;
}

 .banner{
    display: flex;
    justify-content: space-evenly;
    background-color: #ebeae4ab;
    height: 48vh;
    padding-top: 2rem;
    font-family: 'Roboto', sans-serif;
    
  }
  .heading{
    color: black;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 2.80rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;
    text-transform: none;
  }
  .para{
    font-family: "Raleway";
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 1.4;
    color: #F3F8FF;
    text-transform: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
  }

  .Bimg{
    width: 580px;
    height: 329px;
  }

  .css-e53awj-MuiStack-root {
    padding-top: 1rem !important;
    
   }

   .css-sghohy-MuiButtonBase-root-MuiButton-root {
     padding: 2rem;
    border-radius: 0.5rem !important;
   }


 @media (min-width:280px) and (max-width: 768px) {

    .banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4rem;
        height: 100%;
        
      }
      .heading{
        font-size: 1.5rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;   
    padding-left: 1rem;  
    margin-top: 3rem; 
      }

      .para{
        font-size: 1.4rem;
        letter-spacing: 0.00rem;
        opacity: 0.8;
        padding-top: 1rem;
        padding-left: 1rem;
      }

      .css-e53awj-MuiStack-root {
       padding: 1rem;
      }

     

      .left-img{
        display: flex !important;
        justify-content: center !important;
        padding-bottom: 2.5rem;
        margin: 1rem;
      }

	.Bimg{
        width: 77%;
        height: 100%;
      }
      .css-m69qwo-MuiStack-root {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }


}
 

.card-section{

.card-top-text{
    font-size: 2.8rem;
    letter-spacing: 0rem;
    text-align: center;
    margin: 1.8rem 0rem 1.8rem 0rem;
    font-weight: bold;
}

.cardimg{
    width: 311px;
    height: auto;
    box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    padding-left: -1px;
    padding-top: -1px;
    padding-right: -1px;
    padding-bottom: 0px;
    margin-top: 2rem;
}
.card-img{
    margin-left: 1rem;
}
.card-heading{
    text-align: center;
    background-color: #03a9f4;
    margin-bottom: 2.5rem;
    color: #28292A;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: 1rem;
    
}
.card-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1rem;
    grid-row-gap: 0.2rem;
    row-gap: 0.2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #28292A;
    font-size: 1rem;
}
.card-icons{
    margin-right: 0.4rem;
}
.card-dev{
    font-family: "Raleway";
    font-size: 1.0rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 1.55;
    align-items: baseline;
    text-align: left;
    text-transform: none;
    margin-left: 1rem;
    margin-bottom: 1rem;
}


.css-e53awj-MuiStack-root {
    justify-content: center;
    display: flex;
    
}

.cards {
    box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
    padding-top: 20px;
    padding: 1rem;
    

}
.azure{
    background-color: #673ab7;
}
.data{
    background-color: #ff9800;
}
.main-card{
    display: flex;
    justify-content: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}
.cards-all-btn{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}
.secon-main-card{
    padding-top: 2rem;
}
}

@media (min-width:280px) and (max-width:768px){

    .card-section{
    .main-card{
        display: flex;
        flex-direction: column;
    }
    .card-top-text{
        font-size: 1.8rem;
    }
}
}
.contact-detail{
.contact-section{
    display: flex;
    justify-content: space-evenly;
    background-color: #b1e6d682;
    border-color: #17EDAD;
    color: #28292A;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

 

.contact-right-section {
    border-radius: 1rem;
    width: 25%;
}

.contact-heading{
    font-family: "Raleway";
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.01rem;
    line-height: 1.55;
    color: #0C72FF;
    text-transform: uppercase;
    margin-left: 1rem;
}
.get-text{
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 2.8rem;
    letter-spacing: 0.00rem;
    line-height: 1.3;
    text-transform: none;

}
 .phone-number{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
   
} 

.icons{
    display: flex;
    grid-column-gap: 0.3rem;
    -webkit-column-gap: 0.3rem;
            column-gap: 0.3rem;
    margin-top: 5rem;
}
.ficon{
    border-radius: 50%;
    border-color: #0C72FF;
    color: #0C72FF;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    margin: 2px;
    outline: none;
    padding: 0.4rem;
    position: relative;
    background-color: transparent;
    border: 1px solid;
    left: 0px;
    padding: 0.2rem;
    width: 2.2rem;
    height: 2.2rem;

}
.what-btn{
    padding: 1rem;
    height: 98px;
    width: 82px;
    font-weight: bold;
    margin-top: 1rem;
    border: none;
  
}



.css-i4bv87-MuiSvgIcon-root {
    position: absolute !important;
    top: 0.5rem !important;
    left: 0.5rem !important;
}

}

@media(min-width:280px) and (max-width:768px){
    .contact-detail{

    .contact-section {
        display: flex;
        flex-direction: column;
    }
    .contact-heading{
     text-align: center;
    }
    .get-text {
        text-align: center;
    }
     .phone-number {
        margin-left: 2rem;
    }
    .icons{
        margin-left: 2.5rem;
    }
    
    .contact-right-section {
       
        width: 89%;
    }
}
}
.contact-data{
   .cont-text{
    text-align: center;
   }
    .input-text {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    

    .label-text {
      margin-top: 1rem;
    }

    .input-text[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }

.form-data{
  display: flex;
  grid-row-gap: 0.1em;
  row-gap: 0.1em;
  box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
  border-top-style: solid;
  padding: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
}
.submitBtn{
  width: 25%;
    margin-top: 2rem;
    padding: 0.5rem;
    background-color: darkolivegreen;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}
.submitBtn:hover{
  background-color: black;
  color: #fff;
}

}



@media(min-width:280px) and (max-width:768px){

  .contact-right-section {
    border-radius: 1rem;
    margin-top: 2rem;
    padding: 1.5rem;
}


}
.benefits-section{
    background-color: #0039CC;
    border-color: #0039CC;
    color: #F3F8FF;
}

.benefit-top-heading{
    text-align: center;
    font-family: "Poppins";
    font-size: 1.4rem;
    letter-spacing: 0.00rem;
    line-height: 1.2;
    text-transform: none;
    padding-top: 4rem;
}



.main-benefits-section{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
    padding-bottom: 4rem;
    padding-top: 2rem;
}
.top-heading{
    font-family: "Poppins";
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 0.00rem;
    line-height: 1.3;
    text-transform: none;
    text-align: center;
}

.benefit-para{
    font-family: "Raleway";
    font-size: 1.2rem;
    font-weight: normal;
    letter-spacing: 0.00rem;
    line-height: 2rem;
    text-transform: none;
    opacity: 0.8;
    margin-top: 10px;
    text-align: center;


}
.vicon{
    text-align: center;
    box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 1.0);
    background-color: #0C72FF;
    color: #F3F8FF;
    border-color: #0C72FF;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    display: flex;
    align-self: center;
    justify-content: center;
    padding-top: 0.4rem;
}
.video-icon{
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

@media(min-width:280px) and (max-width:768px){

    .main-benefits-section{
        display: flex;
       flex-direction: column;
    }
}

.table-heading{
    text-align: center;
    font-size: 1.0rem;
    color: black;
    padding: 2rem;
   
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 50%;
    border: 2px solid black;
    margin: auto;
    
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  table, th, td {
    border: 1px solid black;
  }
  
  .table-content{
    background-color: #dddddd;
   padding-bottom: 2rem;
  }

  @media(min-width:280px) and (max-width:768px){
    .table-heading{
      font-size: 0.8rem;
     
  }

  td, th {
    padding: 8px;
  }

  table {
    width: 90%;
    
  }

  }
.slick-slide img {
    display: block;
    border-radius: 51px;
    width: 495px;
    height: 265px;
}

@media(min-width:280px) and (max-width:768px){

    .slick-slide img {
        width: 495px;
        height: 265px;
    }
}
.video-content{
    display: flex;
    background-color: cornsilk;
    
}

.video-text{
    width: auto;
    color: black;
    font-weight: bold;
    font-size: 1.6rem;
    padding-left: 2rem;
}
.video-section{
    width: 50%;
    padding: 2rem;
}
.lists{
    list-style-type: circle;
    margin: 1rem;
}


@media(min-width:280px) and (max-width:768px){
    .video-content{
        display: flex;
        flex-direction: column;
    }

    .lists{
        list-style-type: disclosure-closed;
        margin: 0rem;
        font-size: 1rem;
    }
  }
.Blue-Btn{
    max-width: 100%;
}
.footerbar{

      .footer-logo{
        width: 13rem;
      }
    
      .footer-section{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: currentcolor;
      }
      .connectus{
        color: white;
    font-size: 2.4rem;
      }
      .icons{
        display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    margin-top: 1rem;
      }
      .ficon{
        border-radius: 2rem;
        padding: 0.4rem;
        background-color: white;
      }
    }

    @media (min-width:280px) and (max-width:768px){
        
    }





img {
  max-width: 100%;
  height: auto;
}
.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -73px;
    margin-bottom: -57px;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
nav li {
  list-style-type: none;
}
.menu-item a {
  font-size: 19px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}

.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}

.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}

.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 299px;
    left: -47px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #07a8ff !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}

.choose-text{
    margin-top: 1rem;
    padding: 1rem;
    font-size: 2rem;
    color: black;
    font-weight: bold;
    height: 70vh;
    background-color: aqua;
    /* background-image: ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}
.why-text{
    text-align: center;

}
@media(min-width:280px) and (max-width:768px){
    .choose-text{
        font-size: 1rem;
    
    }
}

.contact-data{
   .cont-text{
    text-align: center;
   }
    .input-text {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    

    .label-text {
      margin-top: 1rem;
    }

    .input-text[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }

.form-data{
  display: flex;
  row-gap: 0.1em;
  box-shadow: 0px 0px 3px 3px rgba(17, 34, 54, 1.0);
  border-top-style: solid;
  padding: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
}
.submitBtn{
  width: 25%;
    margin-top: 2rem;
    padding: 0.5rem;
    background-color: darkolivegreen;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}
.submitBtn:hover{
  background-color: black;
  color: #fff;
}

}



@media(min-width:280px) and (max-width:768px){

  .contact-right-section {
    border-radius: 1rem;
    margin-top: 2rem;
    padding: 1.5rem;
}


}
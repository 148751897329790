.contact-detail{
.contact-section{
    display: flex;
    justify-content: space-evenly;
    background-color: #b1e6d682;
    border-color: #17EDAD;
    color: #28292A;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

 

.contact-right-section {
    border-radius: 1rem;
    width: 25%;
}

.contact-heading{
    font-family: "Raleway";
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.01rem;
    line-height: 1.55;
    color: #0C72FF;
    text-transform: uppercase;
    margin-left: 1rem;
}
.get-text{
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 2.8rem;
    letter-spacing: 0.00rem;
    line-height: 1.3;
    text-transform: none;

}
 .phone-number{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
   
} 

.icons{
    display: flex;
    column-gap: 0.3rem;
    margin-top: 5rem;
}
.ficon{
    border-radius: 50%;
    border-color: #0C72FF;
    color: #0C72FF;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    margin: 2px;
    outline: none;
    padding: 0.4rem;
    position: relative;
    background-color: transparent;
    border: 1px solid;
    left: 0px;
    padding: 0.2rem;
    width: 2.2rem;
    height: 2.2rem;

}
.what-btn{
    padding: 1rem;
    height: 98px;
    width: 82px;
    font-weight: bold;
    margin-top: 1rem;
    border: none;
  
}



.css-i4bv87-MuiSvgIcon-root {
    position: absolute !important;
    top: 0.5rem !important;
    left: 0.5rem !important;
}

}

@media(min-width:280px) and (max-width:768px){
    .contact-detail{

    .contact-section {
        display: flex;
        flex-direction: column;
    }
    .contact-heading{
     text-align: center;
    }
    .get-text {
        text-align: center;
    }
     .phone-number {
        margin-left: 2rem;
    }
    .icons{
        margin-left: 2.5rem;
    }
    
    .contact-right-section {
       
        width: 89%;
    }
}
}
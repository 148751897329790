.choose-text{
    margin-top: 1rem;
    padding: 1rem;
    font-size: 2rem;
    color: black;
    font-weight: bold;
    height: 70vh;
    background-color: aqua;
    /* background-image: ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}
.why-text{
    text-align: center;

}
@media(min-width:280px) and (max-width:768px){
    .choose-text{
        font-size: 1rem;
    
    }
}
.footerbar{

      .footer-logo{
        width: 13rem;
      }
    
      .footer-section{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: currentcolor;
      }
      .connectus{
        color: white;
    font-size: 2.4rem;
      }
      .icons{
        display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 1rem;
      }
      .ficon{
        border-radius: 2rem;
        padding: 0.4rem;
        background-color: white;
      }
    }

    @media (min-width:280px) and (max-width:768px){
        
    }
.video-content{
    display: flex;
    background-color: cornsilk;
    
}

.video-text{
    width: auto;
    color: black;
    font-weight: bold;
    font-size: 1.6rem;
    padding-left: 2rem;
}
.video-section{
    width: 50%;
    padding: 2rem;
}
.lists{
    list-style-type: circle;
    margin: 1rem;
}


@media(min-width:280px) and (max-width:768px){
    .video-content{
        display: flex;
        flex-direction: column;
    }

    .lists{
        list-style-type: disclosure-closed;
        margin: 0rem;
        font-size: 1rem;
    }
  }